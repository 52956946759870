<template>
  <div class="view">
    <template>
      <b-card v-if="item">
        <b-row>
          <b-col>
            <b-list-group flush class="list-group-dividered">
              <b-list-group-item style="display: flex">
                <b class="mr-5"> Компания: </b>
                <a
                  v-if="item.client && item.client.extra['amocrm_company_id']"
                  :href="'https://rocketcrm.amocrm.ru/companies/detail/' + item.client.extra['amocrm_company_id']"
                  target="_blank"
                >
                  {{ item.client.name || 'перейти' }}
                </a>
                <template v-else>-</template>

                <template v-if="getLinkByAlias('amoCRM')">
                  <div class="divider"></div>
                  <a :href="getLinkByAlias('amoCRM')" target="_blank"> Сделка </a>
                </template>

                <template v-if="getLinkByAlias('Asana')">
                  <div class="divider"></div>
                  <a v-if="getLinkByAlias('Asana')" :href="getLinkByAlias('Asana')" target="_blank"> Asana </a>
                </template>

                <template v-if="getLinkByAlias('Google Drive')">
                  <div class="divider"></div>
                  <a v-if="getLinkByAlias('Google Drive')" :href="getLinkByAlias('Google Drive')" target="_blank">
                    Google Drive
                  </a>
                </template>

                <template v-if="getLinkByAlias('B2B')">
                  <div class="divider"></div>
                  <a v-if="getLinkByAlias('B2B')" :href="getLinkByAlias('B2B')" target="_blank"> B2B </a>
                </template>
              </b-list-group-item>

              <b-list-group-item style="display: flex">
                <b>Статус сделки: </b>
                <lead-status :item="item" class="ml-5" />
              </b-list-group-item>

              <b-list-group-item>
                <b>Тип сделки: </b>
                {{ item.leadType || '-' }}
              </b-list-group-item>

              <b-list-group-item style="display: flex; align-items: center">
                <b>Участники:</b>
                <followers :item="item" :limit="10" class="ml-5" />
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col>
            <b-list-group flush class="list-group-dividered">
              <b-list-group-item style="display: flex">
                <b>Бюджет:</b>
                <budget :item="item" class="ml-5" />
              </b-list-group-item>
              <b-list-group-item>
                <b>Оплачено:</b>
                {{ item['actual_payments_total'] || 0 | formatNumber }} &#8381; из
                {{ item['payments_total'] | formatNumber }} &#8381;
              </b-list-group-item>
              <b-list-group-item style="display: flex">
                <b>Долг:</b>
                <payments-duty :item="item" class="ml-5" />
              </b-list-group-item>
              <b-list-group-item style="display: flex; align-items: center">
                <b> Часы: </b>
                <hours :item="item" class="ml-5" />
                <div class="divider"></div>
                <b> Сроки: </b>
                <deadline :item="item" class="ml-5" />
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>

        <intervals entityName="projects" />

        <!--<div v-if="getLinkByAlias(data.item._links, 'amoCRM')">-->
        <!--<b>Сделка: </b>-->
        <!--<a :href="getLinkByAlias(data.item._links, 'amoCRM')"-->
        <!--target="_blank">{{data.value || 'перейти'}}</a>-->
        <!--</div>-->
        <!--<div v-if="data.item.client">-->
        <!--<b>Компания: </b>-->
        <!--<a :href="'https://rocketcrm.amocrm.ru/companies/detail/' + data.item.client.extra.amocrm_company_id"-->
        <!--target="_blank">{{data.item.client.name}}</a>-->
        <!--</div>-->
        <!--<div v-if="getLinkByAlias(data.item._links, 'Asana')">-->
        <!--<b>Asana: </b>-->
        <!--<a :href="getLinkByAlias(data.item._links, 'Asana')"-->
        <!--target="_blank">перейти</a>-->
        <!--</div>-->
        <!--<div v-if="getLinkByAlias(data.item._links, 'Google Drive')">-->
        <!--<b>Папка в Google Drive: </b>-->
        <!--<a :href="getLinkByAlias(data.item._links, 'Google Drive')"-->
        <!--target="_blank">перейти</a>-->
        <!--</div>-->
        <!--<div v-if="getLinkByAlias(data.item._links, 'B2B')">-->
        <!--<b>B2B: </b>-->
        <!--<a :href="getLinkByAlias(data.item._links, 'B2B')"-->
        <!--target="_blank">перейти</a>-->
        <!--</div>-->

        <!--<b-tabs class="nav-tabs-line" content-class="pt-20" lazy>-->
        <!--<b-tab title="Интервалы">-->
        <!---->
        <!--</b-tab>-->
        <!--<b-tab title="Задачи">-->

        <!--</b-tab>-->
        <!--<b-tab title="Планирование">-->

        <!--</b-tab>-->
        <!--<b-tab title="Деньги">-->

        <!--</b-tab>-->
        <!--</b-tabs>-->

        <edit-project :source="item" :module="module"></edit-project>
      </b-card>
    </template>
  </div>
</template>

<script>
  import EditProject from './EditProject'
  import Intervals from '../../views/Reports/Intervals/Extension'

  import Responsible from '../../components/Responsible'

  import LeadStatus from './common/LeadStatus'
  import Budget from './common/Budget'
  import PaymentsDuty from './common/PaymentsDuty'
  import Followers from './common/Followers'
  import Hours from './common/Hours'
  import Deadline from './common/Deadline'

  import entityMixins from '../../mixins/entity'

  export default {
    name: 'SingleProject',
    components: {
      EditProject,
      Followers,
      Responsible,
      Intervals,
      LeadStatus,
      Budget,
      PaymentsDuty,
      Hours,
      Deadline,
    },
    mixins: [entityMixins],
    methods: {
      getLinkByAlias(name) {
        let links = this.item['_links']

        if (!links) {
          return null
        }

        let { link } =
          links.find(link => {
            return link.name === name
          }) || {}

        return link || null
      },
    },
    created() {
      let onClick = () => {
        this.$root.$emit('bv::show::modal', 'edit-projects')
      }

      this.$store.commit('currentView/setActions', [
        {
          label: 'Редактировать',
          icon: 'edit',
          onClick,
        },
      ])
    },
    watch: {
      item(item) {
        this.$store.commit('currentView/setMeta', {
          title: item.name,
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .divider {
    height: 18px;
    margin: 0 10px;
    border-right: 1px solid #eee;
  }
</style>
